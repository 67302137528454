<template>
  <div
    class="d-flex align-items-center"
    :class="{
      'flex-row ml-2 p-1': rows,
      'flex-column p-1 p-md-2 m-1 m-md-2': !rows
    }"
    @click="btnShow = !btnShow"
  >
    <div>
      <base-icon
        v-if="rows"
        name="upload-cloud"
        color="#6c757d"
        width="15"
        height="15"
      ></base-icon>
      <base-icon
        v-if="!rows"
        name="upload-cloud"
        color="#6c757d"
        width="30"
        height="30"
      ></base-icon>
    </div>
    <h6
      class="mt-2 text-muted flex-grow-1"
      :class="{
        'ml-2 text-truncate': rows,
        'text-break text-file-grid text-center': !rows
      }"
    >
      <small>{{ file.name }}</small>
    </h6>
    <div class="text-muted" v-if="file.progress === 0">
      <small>{{ file.state }}</small>
    </div>
    <div class="ml-auto">
      <div
        class="progress"
        style="height:4px; width:200px;"
        v-show="file.progress > 1"
      >
        <div
          class="progress-bar"
          v-bind:style="{ width: file.progress + '%' }"
          role="progressbar"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseIcon from "@/components/bases/Icon.vue";

export default {
  name: "UploaderItemNew",
  components: { BaseIcon },
  props: {
    file: {
      Type: Object,
      Requierd: true,
      Default: {}
    },
    errors: {
      Type: Object,
      Requierd: true,
      Default: {}
    },
    rows: {
      Type: Boolean,
      Requierd: true,
      Default: false
    }
  },
  data() {
    return {
      btnShow: false
    };
  },
  methods: {
    fileConvertSize: function(aSize) {
      aSize = Math.abs(parseInt(aSize, 10));
      var def = [
        [1, "octets"],
        [1024, "ko"],
        [1024 * 1024, "Mo"],
        [1024 * 1024 * 1024, "Go"],
        [1024 * 1024 * 1024 * 1024, "To"]
      ];
      for (var i = 0; i < def.length; i++) {
        if (aSize < def[i][0])
          return (aSize / def[i - 1][0]).toFixed(2) + " " + def[i - 1][1];
      }
    }
  }
};
</script>
<style lang="css"></style>
