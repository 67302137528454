<template>
  <div class="mb-3 rounded pl-3 pr-3 pt-2" :class="{ 'shadow-sm': shadow }" v-background-3>
    <div class="d-flex align-items-center border-toolbar mb-1">
      <h6 class="m-0">{{ title }}</h6>
      <div class="ml-auto d-flex flex-row pb-1 pt-1 pr-2">
        <v-popover>
          <button-circle name="info" size="16" v-tooltip="'Obtenir des informations'"></button-circle>
          <template slot="popover">
            <div class="text-left" style="max-width: 400px">
              <small>
                <h4>Informations</h4>
                <p class="text-justify">
                  Qwark peut utiliser vos documents pour compléter automatiquement des champs personnalisés. Cela peut
                  être utile pour les documents et formulaires courants comme les offres, fiches d'exposition, carnet
                  d'accès...
                </p>
                <p class="text-justify">
                  Pour créer un nouveau modèle, vous devez importer ici un document Word. Celui ci doit contenir des
                  balises. Elles seront remplacées par les bonnes informations pendant le téléchargement. Ci après la
                  liste des balises disponibles pour votre modèle :
                </p>
                <div class="d-flex flex-wrap mb-2">
                  <span class="mr-2 mb-2 badge badge-primary p-1" v-for="d in Object.keys(data.values)" :key="d"
                    >${{ "{" + d + "}" }}</span
                  >
                </div>
                <p>
                  Vous pouvez copier / coller ces balises où vous le souhaitez dans le texte de votre document Word.
                </p>
              </small>
            </div>
          </template>
        </v-popover>
        <label class="m-0" :for="id">
          <button-circle name="file-plus" size="16" v-tooltip="'Ajouter un modèle'"></button-circle>
        </label>
      </div>
    </div>
    <div class="d-flex align-items-center" v-if="loading">
      <loader class="m-5"></loader>
      <h2 class="m-0">Chargement...</h2>
    </div>
    <div class="d-flex flex-wrap" :class="{ 'flex-column': rows }">
      <div v-show="!loading" v-for="file in files" :key="file.id" class="hover-file rounded">
        <uploader-item
          :file="file"
          :rows="rows"
          @download="downloadDocument(file)"
          @delete="deleteDocument(file)"
        ></uploader-item>
      </div>
      <div v-show="!loading" v-for="file in filesToUpload" :key="file.id">
        <uploader-item-new :file="file" :rows="rows" :errors="errors"></uploader-item-new>
      </div>
      <div v-if="files.length < 1" class="text-muted m-auto border-0">
        <small>Aucun fichier</small>
      </div>
      <div v-if="errors" class="mt-2">
        <div v-for="err in errors" :key="err.id" class="text-danger">
          <small>{{ err }}</small>
        </div>
      </div>
    </div>
    <!-- DOC UPLOADER -->
    <input :id="id" type="file" @change="addFileToUpload" class="d-none" multiple :accept="file_accepted" />
  </div>
</template>
<script>
import UploaderItem from "@/components/application/TrameUploaderItem.vue";
import UploaderItemNew from "@/components/application/TrameUploaderItemNew.vue";
import Loader from "@/components/bases/Loader.vue";
import ButtonCircle from "@/components/bases/ButtonCircle.vue";

export default {
  name: "Uploader",
  components: { UploaderItem, Loader, UploaderItemNew, ButtonCircle },
  data: function () {
    return {
      files: [],
      filesToUpload: [],
      loading: false,
      progress: 0,
      rows: true,
      errors: [],
    };
  },
  props: {
    id: {
      type: String,
      default: "document",
    },
    url: {
      type: String,
      default: "",
    },
    file_system: {
      type: String,
      default: "",
    },
    file_field: {
      type: String,
      default: "",
    },
    file_key: {
      type: [String, Number],
      default: "",
    },
    file_name: {
      type: String,
      default: "auto",
    },
    file_accepted: {
      type: String,
      default: ".doc, .docx, .xls, .xlsx, .xlsm",
    },
    title: {
      type: String,
      default: "Liste des modèles",
    },
    data: {
      type: Object,
      required: false,
    },
    shadow: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},
  mounted() {
    this.getDocuments();
  },
  methods: {
    getDocuments: function () {
      this.loading = true;
      this.$http
        .get("/trames", {
          params: {
            file_system: this.file_system,
          },
        })
        .then((response) => {
          this.files = response.data;
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },

    downloadDocument: function (file) {
      let file_name = file.file_name + "." + file.file_extension;
      this.$http({
        method: "post",
        url: "/trames",
        data: {
          id: file.id,
          data: this.data,
        },
        responseType: "blob",
      }).then((response) => {
        console.log(file);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file_name);
        document.body.appendChild(link);
        link.click();
      });
      //.finally(() => {});
    },

    deleteDocument: function (file) {
      file.loadingDelete = true;
      this.$http
        .delete("/trames/" + file.id)
        .then(() => {
          const i = this.files.indexOf(file);
          this.files.splice(i, 1);
        })
        .catch((error) => console.log(error));
      //.finally(() => (file.loadingDelete = false));
    },

    addFileToUpload: function (e) {
      let _files = e.target.files || e.dataTransfer.files;
      for (let i = 0; i < _files.length; i++) {
        this.filesToUpload.push({
          file: _files[i],
          name: _files[i].name,
          size: _files[i].size,
          type: _files[i].type,
          progress: 0,
          state: "en attente",
          error: "",
        });
      }
      this.upload();
    },
    upload: async function () {
      this.errors = [];
      for (let i = 0; i < this.filesToUpload.length; i++) {
        let formData = new FormData();
        this.filesToUpload[i].state = "en cours de chargement";
        formData.append("file", this.filesToUpload[i].file);
        formData.append("file_system", this.file_system);
        //formData.append("file_name", this.file_name);
        await this.$http
          .post("/upload/trame", formData, {
            onUploadProgress: (progressEvent) => {
              if (progressEvent.lengthComputable) {
                this.filesToUpload[i].progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
              }
            },
          })
          .then(() => {
            this.filesToUpload[i].state = "Terminé";
            this.filesToUpload[i].progress = 0;
          })
          .catch((error) => this.errors.push(error.response.data.error.file[0]))
          .finally(() => {});
      }
      this.getDocuments();
      this.filesToUpload = [];
    },
  },
};
</script>
<style>
.border-toolbar {
  border-bottom: 1px solid rgb(1, 1, 1, 0.05);
}

.hover-file:hover {
  background-color: rgb(1, 1, 1, 0.03) !important;
}
</style>
