var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 d-flex flex-column"},[_c('div',{staticClass:"mt-3 mr-md-3 d-none"},[_c('trame-uploader',{attrs:{"id":"document_offre","file_system":'OFFRES',"title":"Exportez à partir d'un modèle","data":{
      values: {
        title: _vm.affaire_libelle,
        affaire: _vm.affaire_identifiant,
        month: _vm.$moment().format('MMM YYYY'),
        compte: _vm.compteDescription,
        client: _vm.clientDescription,
        date: this.$moment().format('DD/MM/YYYY'),
        redactor: this.$store.getters['user/data'].name
      },
      tablesSize: 9,
      tables: [],
      images: []}}})],1),_c('base-form',{staticClass:"mt-3 mr-md-3",attrs:{"updateBtn":false,"shadow":""}},[_c('base-form-group',{attrs:{"title":"Offre","dense":"","icon":"book"}},[_c('div',{staticClass:"w-100"},[(_vm.id)?_c('uploader',{attrs:{"id":"document_affaire_prepa","url":"/upload/document","title":"Vos fichiers","file_system":'CRM',"file_field":'offre',"file_key":_vm.id}}):_vm._e()],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }