var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center",class:{
    'flex-row ml-2 p-1 text-break': _vm.rows,
    'flex-column p-1 p-md-2 m-1 m-md-2 cursor-pointer': !_vm.rows
  },attrs:{"title":_vm.file.file_name},on:{"click":function($event){_vm.btnShow = !_vm.btnShow}}},[_c('div',[_c('img',{class:{ 'icon-sm mr-2': _vm.rows, 'icon-md': !_vm.rows },attrs:{"src":'/formats/' + _vm.file.file_extension + '.svg'}})]),_c('div',[_c('span',{staticClass:"mt-2",class:{
        '': _vm.rows,
        'text-break text-file-grid text-center': !_vm.rows
      }},[_c('small',[_vm._v(_vm._s(_vm.file.file_name + "." + _vm.file.file_extension))])])]),(_vm.btnShow || _vm.rows)?_c('div',{staticClass:"text-muted d-flex align-items-center",class:{ 'mt-2': !_vm.rows, 'ml-auto': _vm.rows }},[(!_vm.file.user_id)?_c('ButtonCircle',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Disponible pour tous les utilisateur'),expression:"'Disponible pour tous les utilisateur'"}],attrs:{"loading":_vm.file.loadingDownload,"size":"18","name":"eye","colorClass":"btn-dark"}}):_vm._e(),(_vm.file.user_id)?_c('ButtonCircle',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Disponible uniquement pour vous'),expression:"'Disponible uniquement pour vous'"}],attrs:{"loading":_vm.file.loadingDownload,"size":"18","name":"eye-off","colorClass":"btn-primary"},on:{"click":function($event){return _vm.$emit('publishForAll')}}}):_vm._e(),(_vm.file.user_id)?_c('ButtonCircle',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Supprimer'),expression:"'Supprimer'"}],attrs:{"loading":_vm.file.loadingDelete,"size":"18","name":"x","colorClass":"btn-danger"},on:{"click":_vm.deleteFile}}):_vm._e(),_c('ButtonCircle',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
        'Télécharger le modèle complété (' +
          _vm.fileConvertSize(_vm.file.file_size) +
          ')'
      ),expression:"\n        'Télécharger le modèle complété (' +\n          fileConvertSize(file.file_size) +\n          ')'\n      "}],attrs:{"loading":_vm.file.loadingDownload,"size":"18","name":"download","colorClass":"btn-success"},on:{"click":function($event){return _vm.$emit('download')}}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }